:root {
    --menubar-height: 7rem;
    --mobile-menubar-height: 5rem;
}

.nav-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    justify-content: center;
    width: 100vw;
    background-color: white;
}

#navbar-block {
    display: flex;
    justify-content: center;
}

.navbar {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    font-weight: bold;
    height: 5rem;
    width: 90rem;
    padding: 0 3rem;
}

.menu-items {
    text-decoration: none;
    color: black;
}

.menu-items:hover {
    text-decoration: underline;
}

.active {
    text-decoration: none;
    color: var(--site-green-color);
}

.site-logo {
    display: none;
}

.site-title {
    margin-right: auto;

}

#title {
    height: 3rem;
    display: flex;
}

#logo {
    height: 3rem;
    display: flex;
}

.nav-btn {
    background: transparent;
    border: none;
    color: var(--site-green-color);
    display: none;
    font-size: 2rem;
    cursor: pointer;
}

.nav-btn:hover {
    opacity: 0.5;
}

#nav-block {
    display: flex;
    justify-content: center;
    background-color: var(--site-green-color);
}

#nav-block-container {
    display: flex;
    height: 2rem;
    color: white;
    align-items: center;
    gap: 0.5rem;
    width: 90rem;
    padding: 0 3rem;

    #mail-icon {
        margin-left: auto;
    }

}


.mobile-nav {
    display: none;
    flex-direction: column;
    background-color: white;

    a {
        text-decoration: none;
        color: var(--site-green-color);
        font-weight: bold;
        padding: 0.5rem 0;
        width: 100%;
        text-align: center;

        /* border-top-style: solid;
        border-left-style: solid;
        border-right-style: solid;
        border-width: 1px; */
    }

    a:hover {
        text-decoration: underline;
    }
}

@media only screen and (max-width: 900px) {
    .menu-items {
        display: none;
    }

    .nav-open-btn {
        display: flex;
    }

    .site-title {
        display: none;
    }

    .site-logo {
        display: flex;
        margin-right: auto;
    }

    .navbar {
        padding: 0 3rem;

    }

    .responsive_nav {
        .nav-open-btn {
            display: none;
        }

        .nav-close-btn {
            display: flex
        }

        .mobile-nav {
            display: flex;
            flex-direction: column;
        }

    }

    @media only screen and (max-width: 600px) {
        #nav-block {
            display: none;
        }
    }


}