#products-page {
    display: flex;
    /* flex-direction: column;
    align-items: flex-start;
    align-items: center; */
    justify-content: center;
    background-color: lightgrey;
    width: 100vw;
    flex-wrap: wrap;
}

#products-block {
    display: flex;
    width: 100%;
    background-color: var(--site-black-color);
    justify-content: center;
    align-items: center;
    color: white;
    height: 15rem;
    font-size: 4rem;
}

#products-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 90rem;
    background-color: white;
    padding: 2rem 2rem;
    align-items: center;
    /* border-style: solid; */

    margin: 1rem 1rem;
}

.products-title {
    font-size: 2rem;
    text-align: center;
    /* font-weight: bold; */
    color: var(--site-green-color);
    /* border-style: solid; */
}

#products-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 3rem;
    /* border-style: solid; */
    gap: 2rem;
    width: 70rem;
    justify-content: center;
}

.grid-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    color: black;
    text-align: center;
}

.grid-image {
    height: 15rem;
}

@media only screen and (max-width: 900px) {

    #products-container {
        width: auto;
    }

    #products-grid {
        width: auto;
    }

    .grid-image {
        width: 100%;
        height: 100%;
    }

    /* @media only screen and (max-width: 600px) {
        #products-container {
            width: 100%;
        }

    } */
}