#contact-page {
    display: flex;
    justify-content: center;
    background-color: lightgrey;
    width: 100vw;
    flex-wrap: wrap;
}

#contact-block {
    display: flex;
    width: 100%;
    background-color: var(--site-black-color);
    justify-content: center;
    align-items: center;
    color: white;
    height: 15rem;
    font-size: 4rem;
    text-align: center;
}

#contact-container {
    display: flex;
    min-height: 100%;
    width: 90rem;
    background-color: white;

    margin: 1rem 1rem;

}

.contact-item {
    padding: 2rem 2rem;
    /* border-style: solid; */
}


#info-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 40%;
}

#info-container .info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#google-container {
    width: 60%;
}

.item-title {
    font-weight: bold;
}

.info-title {
    font-size: 2rem;
    /* font-weight: bold; */
    color: var(--site-green-color);
}

#map {
    padding-top: 2rem;
}

@media only screen and (max-width: 900px) {
    #contact-container {
        flex-direction: column;
    }

    #info-container {
        width: auto;
    }

    #google-container {
        width: auto;
    }

    @media only screen and (max-width: 600px) {
        #contact-container {
            width: 100vw;
        }

    }
}