#about-page {
    display: flex;
    justify-content: center;
    background-color: lightgrey;
    width: 100vw;
    flex-wrap: wrap;
}

#about-block {
    display: flex;
    width: 100%;
    background-color: var(--site-black-color);
    justify-content: center;
    align-items: center;
    color: white;
    height: 15rem;
    font-size: 4rem;
    text-align: center;
}

#about-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 90rem;
    background-color: white;
    gap: 2rem;
    padding: 2rem 0;

    margin: 1rem 1rem;
}

.about-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 2rem;
    /* border-style: solid; */
    gap: 1rem;


}

.about-title {
    font-size: 2rem;
    /* border-style: solid; */
    text-align: center;
    color: var(--site-green-color);
}

.text-container {
    /* border-style: solid; */
    width: 40rem;
}

@media only screen and (max-width: 900px) {
    .text-container {
        width: auto;
    }

    @media only screen and (max-width: 600px) {
        #about-container {
            width: 100vw;
        }

    }
}