#home-page {
    display: flex;
    justify-content: center;
    background-color: lightgrey;
    width: 100vw;
    flex-wrap: wrap;
    /* border-style: solid; */
}

/* .space-block {
    height: 1rem;
} */

#home-container {
    display: flex;
    flex-direction: column;
    width: 90rem;
    margin: 1rem 1rem;
}


/* products styling */

#home-products-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 2rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    gap: 1rem;

    /* height: 300px; */
}

#home-products-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
}

#slideshow {
    display: flex;
    width: 100%;
    gap: 2rem;
    /* padding: 1rem 0; */
    justify-content: center;
    flex-wrap: wrap;
}

.g-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.g-image {
    height: 13rem;
}

.g-item-text {
    text-align: center;
    /* margin: 1rem; */
    font-weight: bold;
}

/* about block styling */

#home-about-block {
    display: flex;
    flex-direction: column;
    /* border-style: solid; */
    padding: 2rem 2rem;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

}

/* about title styling */

#home-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    /* color: var(--site-green-color); */
}

#home-title-2 {
    color: black;
}

/* about text styling */

#about-text {
    display: flex;
    justify-content: center;

}

#about-text p {
    width: 60rem;
}

/* About button styling */

.button-wrapper {
    display: flex;
    justify-content: center;
}

.view-button {
    text-align: center;
    align-content: center;
    /* border-style: solid; */
    text-decoration: none;
    width: 10rem;
    height: 3rem;
    background-color: var(--site-green-color);
    color: white;
    font-size: 1rem;
    transition: 0.5s;
}

.view-button:hover {
    opacity: 0.7;
}

/* google maps styling */

#home-google-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 2rem;
    text-align: center;
    gap: 1rem;
    /* width: auto; */
}

#google-map {
    width: 100%;
}

#home-google-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

#contact-button-wrapper {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    #home-products-container {
        width: auto;
    }

    .g-image {
        height: 100%;
        width: 100%;
    }

    /*

    #home-about-block {
        width: auto;
    }

    #home-google-container {
        width: auto;
    } */

    #about-text p {
        width: auto;
    }


    /* @media only screen and (max-width: 600px) {
        #contact-container {
            width: 100vw;
        }

    } */
}