* {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

:root {
    --site-green-color: #095047;
    --site-black-color: #303030;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


main {
    margin-top: var(--menubar-height);
    /* min-height: 100vh; */
    /* background-image: url("./assets/image.png"); */
}

@media only screen and (max-width: 600px) {
    main {
        margin-top: var(--mobile-menubar-height);
    }
}